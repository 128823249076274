<template>
  <v-app>
    <v-card>
      <v-card-title>
        {{ `${edit ? 'Update' : 'Add'}` }} online classroom
      </v-card-title>
      <v-card-text>
        <!--        <pre v-if="room.id">{{ room }}</pre>-->
        <v-row>

          <v-col cols="6">
            <v-select
                :loading="loading"
                label="Academic year" :items="academicYears" item-value="id"
                @change="changeAcademicYear()" item-text="title"
                v-model="room.academic_year_id" outlined dense>
            </v-select>

          </v-col>
          <v-col cols="6">
            <v-select
                :loading="loading"
                label="Level" :items="levels" item-value="id" item-text="title"
                v-model="room.level_id" outlined dense
                @change="getPrograms(room.level_id)"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
                :loading="loading"
                label="Programs" :items="programs" item-value="id" item-text="title"
                v-model="room.program_id" outlined dense
                @change="getGrades(room.program_id)"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
                :loading="loading"
                label="Grade" :items="gradesLevels" item-value="id" item-text="title"
                v-model="room.grade_id" outlined dense
                @change="getClasses()"
            >
            </v-select>
          </v-col>
          <v-col cols="6  ">
            <v-select
                :items="academic_classes"
                item-value="id"
                item-text="title"
                v-model="room.linked_class"
                label="Select classroom"
                dense
                outlined
                persistent-hint
                small-chips
            >

            </v-select>
            <span class="text-danger"
                  v-if="$v.room.linked_class.$error">This information is required</span>
          </v-col>
          <v-col cols="6">
            <v-select
                :items="courses"
                item-text="title_subject_code"
                item-value="id"
                v-model="room.linked_subject"
                label="Select subject"
                @change="setClassTitle"
                dense
                outlined
                persistent-hint

            >

            </v-select>
            <span class="text-danger"
                  v-if="$v.room.linked_subject.$error">This information is required</span>
          </v-col>

          <v-col cols="12">

            <v-text-field label="Class name" v-model="room.title" outlined dense>
            </v-text-field>
            <span class="text-danger" v-if="$v.room.title.$error">This information is required</span>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
                :loading="loading"
                :items="zoom_teachers"
                item-text="full_name"
                item-value="id"
                v-model="room.linked_teacher"
                label="Select teacher/user"
                dense
                outlined
                persistent-hint
                :hint="room.online_medium!='zoom' && room.online_medium!='' ? '' : 'Teacher with zoom verified account are listed'"
                @change="getUser(room.linked_teacher)"
            >
            </v-autocomplete>
            <span>{{room.online_medium!='zoom' && room.online_medium!='' ? '' : '' }}</span><br>
            <span class="text-danger" v-if="$v.room.linked_teacher.$error">This information is required</span>
          </v-col>
          <v-col cols="12">
            <v-select
                v-model="room.online_medium"
                outlined
                dense
                :items="online_mediums"
                item-text="title"
                item-value="value"
                label="Classroom Type"
            >

            </v-select>
            <span class="text-danger" v-if="$v.room.online_medium.$error">This information is required</span>
          </v-col>
          <v-col cols="12" v-if="room.online_medium!='zoom' && room.online_medium!=''">
            <v-text-field label="Meeting link" v-model="room.meeting_link" outlined dense></v-text-field>
          </v-col>

        </v-row>
        <template>
          <v-row>
            <v-col cols="12">
              <v-switch label="Starts now" v-model="room.is_instant" :persistent-hint="true" @change="setInstantTime" hint="Instant meeting may take 3 minutes to create"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <b>When </b>
            </v-col>
            <v-col cols="5">
              <datetime class="p-2 border border-dark" label="When" @input="onDurationSelected"
                        placeholder="Start date"
                        :minute-step="15"
                        format="yyyy-MM-dd"
                        use12-hour
                        type="date"
                        :min-datetime="minDatetime"
                        v-model="room.start_date">
                <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
              </datetime>
              <span class="text-danger" v-if="$v.room.start_date.$error">This information is required</span>
            </v-col>

            <v-col cols="5">
              <datetime class="p-2 border border-dark"
                        placeholder="Time"
                        :minute-step="5"
                        format="hh:mm"
                        use12-hour
                        type="time"
                        v-model="room.start_time_raw">
                <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
              </datetime>
              <span class="text-danger" v-if="$v.room.start_time_raw.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <b>Duration</b>
            </v-col>
            <v-col cols="5">
              <v-select
                  @change="onDurationSelected"
                  v-model="room.duration_hour"
                  outlined
                  dense
                  :items="duration_hour"
                  item-value="value"
                  item-text="text"
                  suffix="hr"
                  label="Select hour"
              >

              </v-select>
            </v-col>

            <v-col cols="5">
              <v-select
                  @change="onDurationSelected"
                  v-model="room.duration_minute"
                  outlined
                  dense
                  :items="duration_minutes"
                  item-value="value"
                  item-text="text"
                  suffix="min"
                  label="Select minutes"

              >

              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <b>Schedule recurrence</b>
            </v-col>
<!--            <v-col cols="5">-->
<!--              <v-autocomplete-->
<!--                  v-model="room.timezone"-->
<!--                  outlined-->
<!--                  :searchable="true"-->
<!--                  dense-->
<!--                  :items="timezones"-->
<!--                  item-value="timezone"-->
<!--                  item-text="timezone"-->
<!--                  label="Time Zone"-->
<!--              >-->

<!--              </v-autocomplete>-->
<!--            </v-col>-->

            <v-col cols="5">
              <v-switch v-model="room.recurring_meeting" label="Recurring"></v-switch>
            </v-col>
          </v-row>

          <v-row v-if="room.recurring_meeting">

            <v-col cols="2">
              <b>Recurring setting</b>
            </v-col>

            <v-col cols="5" v-if="room.recurring_meeting">
              <v-select
                  v-model="recurrence.type"
                  outlined
                  dense
                  item-text="week_day"
                  item-value="value"
                  :items="recurrences"
                  label="Recurrence type"
              >

              </v-select>
            </v-col>
            <v-col cols="5" v-if="room.recurring_meeting">
              <v-text-field
                  v-model="recurrence.repeat_interval"
                  outlined
                  dense
                  type="number"
                  prefix="Repeat every"
                  :suffix="`${recurrence.type==1 ? 'day': recurrence.type==2 ?  'week' : 'month' }`"
              >

              </v-text-field>
            </v-col>


          </v-row>
          <v-row>
            <v-col cols="2" v-if="room.recurring_meeting">
              <b>End Date</b>
            </v-col>
            <v-col cols="4" v-if="room.recurring_meeting">
              <datetime class="p-2 border border-dark"
                        placeholder="Class recurrence end date"
                        :minute-step="15"
                        format="yyyy-MM-dd"
                        use12-hour
                        type="date"
                        :min-datetime="room.start_date"
                        v-model="room.end_date">
                <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
              </datetime>
            </v-col>
            <v-col cols="6" v-if="room.recurring_meeting && recurrence.type == 2">
              <v-select label="Class Days" :items="week_days" item-value="value" item-text="week_day"
                        multiple v-model="recurrence.weekly_days" outlined dense>

              </v-select>
            </v-col>

          </v-row>
          <v-row>

            <v-col cols="2">
              <b>Passcode</b>
            </v-col>
            <v-col cols="2">
              <v-switch label="Requires passcode" v-model="room.required_pass_code" outlined dense>

              </v-switch>
            </v-col>

            <v-col cols="4" v-if="room.required_pass_code">
              <v-text-field outlined dense label="Enter passcode"></v-text-field>
            </v-col>

          </v-row>
        </template>
        <!--        <v-row>-->
        <template v-if="room.online_medium =='zoom'">
          <v-row>
            <v-col cols="2">
              <b>Video</b>
            </v-col>
            <v-col cols="2">
              <v-switch v-model="settings.host_video" label="Enable host video">

              </v-switch>
            </v-col>
            <v-col cols="8">
              <v-switch v-model="settings.participant_video" label="Enable participant video">

              </v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <b>Settings</b>
            </v-col>
            <v-col cols="3">
              <v-switch v-model="settings.join_before_host" label="Waiting room">
              </v-switch>
            </v-col>
            <v-col cols="3">
              <v-switch v-model="settings.join_before_host" label="Join before entry">
              </v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch v-model="settings.mute_upon_entry" label="Mute participants upon entry">

              </v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <b>Approval</b>
            </v-col>
            <v-col cols="4">
              <v-select v-model="settings.approval_type"
                        outlined
                        dense
                        :items="approval_type" item-text="text"
                        item-value="value" label="Approval type">

              </v-select>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            <div class="text-right float-right">
              <v-btn depressed @click="redirectTo" class="text-gray btn btn-standard">
                Cancel
              </v-btn>
              <v-btn depressed :loading="isBusy" @click="createOrUpdate" v-if="checkIsAccessible('online-class-room', 'create')"
                     class="text-white ml-2 btn btn-primary ">
                Save
              </v-btn>
            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-app>

</template>
<script>
import FilterMixin from "@/mixins/FilterMixin";
import {required} from "vuelidate/lib/validators";
import OnlineClassRoomsService from "@/core/services/online-class-rooms/OnlineClassRoomsService";
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import userService from "@/core/services/user/UserService";

const onlineCLassRoom = new OnlineClassRoomsService();
const userSer = new userService();

export default {
  name: 'rooms',
  mixins: [FilterMixin],
  validations: {
    room: {
      title: {required},
      linked_teacher: {required},
      online_medium: {required},
      start_date: {required},
      start_time_raw: {required},
      linked_class: {required},
      linked_subject: {required},

    }
  },
  components: {datetime: Datetime},
  computed: {
    class_room_id() {
      return this.$route.params.room_id;
    },
  },
  data() {
    return {
      timezones: [
        {'title': "Asia/Kathmandu", value: 'Asia/Kathmandu'}
      ],
      programs: [],
      user: {},
      dialog: false,
      edit: false,
      menu: false,
      menu1: false,
      sizeExceed: false,
      isBusy: false,
      minDatetime: new Date().toISOString(),
      search: {
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: '',
        type: 'teacher',
        limit: 100,
        zoom_teacher: false
      },
      week_days: [
        {week_day: 'Sunday', value: 1},
        {week_day: 'Monday', value: 2},
        {week_day: 'Tuesday', value: 3},
        {week_day: 'Wednesday', value: 4},
        {week_day: 'Thursday', value: 5},
        {week_day: 'Friday', value: 6},
        {week_day: 'Saturday', value: 7},
      ],
      duration_hour: [
        {text: '0', value: 0},
        {text: '1', value: 1},
        {text: '2', value: 2},
        {text: '3', value: 3},
        {text: '4', value: 4},
        {text: '5', value: 5},
        {text: '6', value: 6},
        {text: '7', value: 7},
        {text: '8', value: 8},
        {text: '9', value: 9},
        {text: '9', value: 9},
        {text: '10', value: 10},
      ],

      duration_minutes: [
        {text: '0', value: 0},
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '15', value: 15},
        {text: '20', value: 20},
        {text: '25', value: 25},
        {text: '30', value: 30},
        {text: '35', value: 35},
        {text: '40', value: 40},
        {text: '45', value: 45},
        {text: '50', value: 50},
        {text: '55', value: 55},
      ],
      recurrences: [
        {week_day: 'Daily', value: 1},
        {week_day: 'Weekly', value: 2},
        {week_day: 'Monthly', value: 3},
      ],
      approval_type: [
        {text: 'Automatically approve', value: 0},
        {text: 'Manually approve', value: 1},
        // {text: 'No Registration Required', value: 2},
      ], online_mediums: {},

      room: {
        title: null,
        start_date: null,
        start_time: null,
        start_time_raw: null,
        end_date: null,
        is_active: true,
        duration_hour: null,
        duration_minute: null,
        timezone: '(GMT+05:45) Kathmandu',
        online_medium: 'zoom',
        recurring_meeting: false,
        meeting: {
          recurrence: {},
          settings: {}
        }
      },
      meeting: {
        duration: null,
        start_time: null,
        topic: null,
        type: null,
      },
      recurrence: {
        type: null,
        repeat_interval: null,
        weekly_days: null,
        monthly_day: null,
        end_date_time: null,
      },
      settings: {
        host_video: true,
        participant_video: false,
        join_before_host: true,
        mute_upon_entry: true,
        approval_type: 1,
      }
    };
  },
  mounted() {
    if (this.class_room_id) {
      this.getOnlineClassRoom()
    }
    this.getIncompletedAcademicYears()
    this.getLevels()
    this.buildOnlineMedium()
    this.getAllTimeZones();
    this.getTeachers();
  },
  methods: {

    setClassTitle(){
      let course = this.courses.find(item => item.id === this.room.linked_subject);
      this.room.title = course.title;
    },
    showModal(id = null) {
      if (id) {
        this.edit = true
        this.getRoom(id)
      }
      this.dialog = true;
      // this.$refs["my-modal"].show();
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    }, getRoom(id) {
      onlineCLassRoom.show(id).then(response => {
        this.room = response.data.rooms
      })
      this.getLevels();
    },
    getAllTimeZones() {
      onlineCLassRoom
          .getAllTimeZones()
          .then(response => {
            this.timezones = response.data
          });
    },
    getClasses() {
      this.getAcademicClasses(this.room.academic_year_id, this.room.program_id, this.room.grade_id);
      this.getGardeCourses();
    },

    getTeachers(){
      let search = {
        // program_id: this.room.program_id,
        // grade_id: this.room.grade_id,
        type: 'teacher',
        limit: 100,
        // zoom_teacher: this.room.online_medium=='zoom' ? true : false
      }
      this.getAvailableTeachersOnlineClassroom(search);
    },

    getGardeCourses() {
      let search = {
        program_id: this.room.program_id,
        grade_id: this.room.grade_id,
      }
      this.getCourses(search);
    },
    createOrUpdate() {
      this.$v.room.$touch();
      if (this.$v.room.$error) {
        setTimeout(() => {
          this.$v.room.$reset();
        }, 3000);
      } else {
        this.room.meeting.recurrence = this.recurrence
        this.room.meeting.settings = this.settings
        this.room.start_date = this.formatDate(new Date(this.room.start_date).toDateString());
        this.room.end_date = this.room.end_date ? this.formatDate(new Date(this.room.end_date).toDateString()) : null;
        this.room.start_time = this.formatTime(this.room.start_time_raw);
        if (this.edit) {
          this.updateRoom();
        } else {
          this.createRoom();
        }
      }
    },

    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    formatTime(date) {

      let day = new Date(date);
      if (isNaN(day.getTime())) {
        return date;
      } else {
        let hour = day.getHours(), minute = day.getMinutes(), l = "AM";

        if (hour < 10) {
          hour = '0' + hour;
        }
        if (minute < 10) {
          minute = '0' + minute;
        }

        return hour + ':' + minute;
      }


    },
    updateRoom(fd) {
      this.isBusy = true;
      this.$bus.emit('toggleLoader');

      onlineCLassRoom
          .update(this.room.id, this.room)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.resetForm();
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
            // console.log(error);
          }).finally(() => {
        this.$bus.emit('toggleLoader');
        this.isBusy = false;
      });
    },

    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    changeAcademicYear() {
      this.getLevels()
    },
    getOnlineClassRoom() {
      this.isBusy = false;
      onlineCLassRoom.show(this.class_room_id).then(response => {
        this.edit = true
        this.room = response.data.rooms;
        this.recurrence.type = this.room.type
        this.recurrence.repeat_interval = this.room.repeat_interval
        this.settings = this.room.settings
        if (this.class_room_id && this.room.level_id) {
          this.getPrograms(this.room.level_id);
          if (this.room.program_id) {
            this.getGrades(this.room.program_id)
            this.getClasses()
          }
        }

        this.room.linked_subject = parseInt(this.room.linked_subject)
        this.room.linked_class = parseInt(this.room.linked_class)



        this.getMeetingDetail();
      }).catch().finally(() => {
        this.isBusy = false;
      })
    },
    getMeetingDetail() {
      onlineCLassRoom.getMeetingDetail(this.room.meeting_id).then(response => {
        let data = response.data;
        this.meeting = data.meeting
        this.recurrence = data.recurrence;
        this.settings = data.setting;
        this.room.meeting.recurrence = this.recurrence
        this.room.meeting.settings = this.settings
        if (this.recurrence.weekly_days) {
          let days = this.recurrence.weekly_days.split(',');
          this.recurrence.weekly_days = days.map(ele => {
            return parseInt(ele)
          });
        }
      })
    },
    createRoom() {
      this.isBusy = true;
      this.$bus.emit('toggleLoader');
      onlineCLassRoom
          .store(this.room)
          .then((response) => {
            if(response && !response.status){
              this.$snotify.error(response.msg);
            }else{
              this.isBusy = false;
              this.resetForm();
              this.$snotify.success("Information added");
            }
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
            this.$snotify.error(`${error.message}`);
            // console.log(error);
          }).finally(() => {
        this.isBusy = false;
        this.$bus.emit('toggleLoader');
      });
    },
    redirectTo() {
      this.$router.push({name: 'online-class-rooms'})
    },
    resetForm() {
      this.$v.room.$reset();
      this.edit = false;
      this.room = {
        title: null,
        is_active: null
      };
      this.$tabs.close().then(close => {
        this.$router.push({name: 'online-class-rooms'})
      })
    },
    onDurationSelected() {
      if (this.room.start_date && this.room.duration) {
        let duration = this.room.duration
        let endsOn = this.$moment(this.room.start_date).add(duration, 'minutes').format();
        this.room.end_date = endsOn
      }
    },
    getUser() {
      userSer.show(this.room.linked_teacher).then(response => {
        this.user = response.data.user
        this.buildOnlineMedium()
      })
    },
    buildOnlineMedium() {
      this.online_mediums = [
        {value: 'zoom', title: 'Zoom integrated classroom'},
        {value: 'zoom_link', title: 'Zoom meeting - external Link'},
        {value: 'meet', title: 'Google meet - external Link'},
        {value: 'teams', title: 'Microsoft teams - external Link'},
      ]
      // if (this.user && !this.user.zoom_account) {
      //    this.online_mediums.splice(1,1);
      // }
    },
    setInstantTime() {
      if (this.room.is_instant) {
        this.room.start_date = this.$moment(new Date).format('YYYY-MM-DD');
        this.room.start_time = this.$moment(new Date).add(3, 'minute').format();
        this.room.start_time_raw = this.$moment(new Date).add(3, 'minute').format();
        this.room.end_date = this.$moment(new Date).add(duration, 'minute').format('YYYY-MM-DD');
        // this.room.end_date = this.$moment(new Date).add(5, 'minute').format('HH:mm')
      } else {
        this.room.start_date = null;
        this.room.end_date = null;
      }
    }
  }
}
;
</script>
